<template>
  <div>
    <div class="wallet-container">
      <div class="header">
        <span>Cancel</span> |
        <router-link to="/">Home</router-link> |
        <router-link :to="{ name: 'game' }">Game</router-link> |
        <router-link :to="{ name: 'game2' }">Game2</router-link>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppMain',
  setup() {
    const { WebApp } = window.Telegram;

    if (typeof WebApp?.expand === 'function') {
      WebApp.expand();
    }
  },
};
</script>
