<template>
  <div>
    <div class="logo">
      <img alt="Vue logo" src="../assets/logo.png" width="100" align="middle" />
    </div>
    <div class="balance">
      <p>Total balance</p>
      <h1>$4,170.00</h1>
      <p>+$2.00 +0.44%</p>
    </div>
    <div class="actions">
      <div>
        <button class="buy">Buy</button>
      </div>
      <div>
        <button class="p2p">P2P</button>
      </div>
      <div>
        <button class="swap">Swap</button>
      </div>
    </div>
    <div class="tokens">
      <div class="token">
        <div>BNB</div>
        <div>110 USDT</div>
        <div class="amount">+0.05%</div>
      </div>
      <div class="token">
        <div>BNB</div>
        <div>110 USDT</div>
        <div class="amount">+0.05%</div>
      </div>
      <div class="token">
        <div>BNB</div>
        <div>110 USDT</div>
        <div class="amount">+0.05%</div>
      </div>
      <div class="token">
        <div>BNB</div>
        <div>110 USDT</div>
        <div class="amount">+0.05%</div>
      </div>
      <div class="token">
        <div>BNB</div>
        <div>110 USDT</div>
        <div class="amount">+0.05%</div>
      </div>
      <div class="token">
        <div>BNB</div>
        <div>110 USDT</div>
        <div class="amount">+0.05%</div>
      </div>
      <div class="token">
        <div>BNB</div>
        <div>110 USDT</div>
        <div class="amount">+0.05%</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
};
</script>
